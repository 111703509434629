import React from 'react';
import {
    Row,
    Button,
    Col,
    // Menu,
    Modal,
    // Dropdown,
    Input,
    // Form,
    message,
    Image,
} from 'antd';
// import { PlusCircleOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { actions } from '../../../redux/module/panel'
import * as Params from '../../../common/param/Params'
import {axiosGet, axiosPostBody, axiosPut} from '../../../util/Request';
import moment from "moment";
// import {FileWordTwoTone} from "@ant-design/icons";
// import {COS_URL} from "../../../common/param/Params";


class UserSearch extends React.Component {
    groupForm = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            chooseUser: {},
            showCreateGroup: false,
            hasUser: false,
            queryUser: {
                username: '',
                nickname: '',
                is_email:false,
            },
        }
    }

    componentDidMount() {

    }

    /**
     * 搜索用户Api
     * @param {*} value
     * @param {*} _event
     * @returns
     */
    searchUserApi = (value, _event) => {
        if (null === value || "" === value) {
            return
        }

        let data = {
            name: value.trim()
        }
        axiosGet(Params.USER_NAME_URL, data)
            .then(response => {
                let data = response.data
                if (data.user.email === "" && data.user.nick_name === "") {
                    message.error("未查找到该用户")
                    return
                }
                let queryUser = {
                    username: data.user.email,
                    nickname: data.user.nick_name,
                    is_email: data.user.email === value.trim() ? true : false,
                    // groupUuid: data.group.uuid,
                    // groupName: data.group.name,
                }
                this.setState({
                    hasUser: true,
                    queryUser: queryUser
                });
            });
    }


    /**
     * 搜索用户
     * @param {*} value
     * @param {*} _event
     * @returns
     */
    searchUser = (value, _event) => {
        if (null === value || "" === value) {
            return
        }

        let name = value.trim();

        let userList = this.props.userList;
        for (var index in userList) {
            if (userList[index].username === name || userList[index].nickname === name) {
                userList[index].hasUnreadMessage = 0;
                this.props.setUserList(userList);
                this.chooseUser(userList[index])
                break;
            }
        }

    }

    /**
     * 选择用户，获取对应的消息
     * @param {选择的用户} value
     */
    chooseUser = (value) => {
        let chooseUser = {
            toUser: value.uuid,
            toUsername: value.username,
            toNickname: value.nickname,
            companyName: value.companyName,
            messageType: value.messageType,
            avatar: value.avatar !== '' ?  value.avatar : Params.HOST + "/file/default.png",
        }
        this.fetchMessages(chooseUser);
        this.removeUnreadMessageDot(value.uuid);
    }

    /**
     * 获取消息
     */
    fetchMessages = (chooseUser) => {
        const { messageType, toUser, toUsername } = chooseUser
        let uuid = localStorage.uuid
        if (messageType === 2) {
            uuid = toUser
        }
        let data = {
            Uuid: uuid,
            FriendUsername: toUsername,
            MessageType: messageType
        }

        moment.defineLocale('zh-cn', {
            relativeTime: {
                future: '%s内',
                past: '%s前',
                s: '几秒',
                m: '1分钟',
                mm: '%d分钟',
                h: '1小时',
                hh: '%d小时',
                d: '1天',
                dd: '%d天',
                M: '1个月',
                MM: '%d个月',
                y: '1年',
                yy: '%d年'
            },
        });

        axiosGet(Params.MESSAGE_URL, data)
            .then(response => {
                let comments = []
                let data = response.data
                if (null == data) {
                    data = []
                }
                console.log('usersearch',data)
                for (var i = 0; i < data.length; i++) {
                    let contentType = data[i].contentType
                    let content = this.getContentByType(contentType, data[i].url, data[i].content)

                    let comment = {
                        author: data[i].fromUsername + '('+ data[i].fromCompanyName +')',
                        avatar: data[i].avatar !== '' ? data[i].avatar : Params.HOST + "/file/default.png",
                        content: <div style={{whiteSpace:'pre-wrap'}}>{content}</div>,
                        datetime: moment(data[i].createAt).fromNow(),
                    }
                    comments.push(comment)
                }

                this.props.setMessageList(comments);
                // 设置选择的用户信息时，需要先设置消息列表，防止已经完成了滑动到底部动作后，消息才获取完成，导致消息不能完全滑动到底部
                this.props.setChooseUser(chooseUser);
            });
    }

    openFile = (url,type) => {
        console.log('userSearch')
        if(type === 2 ){
            window.open(Params.COS_URL + '/' + url,'__blank')
        }else{
            return false
        }
    }

    /**
     * 根据文件类型渲染对应的标签，比如视频，图片等。
     * @param {文件类型} type
     * @param {文件地址} url
     * @returns
     */
    getContentByType = (type, url, content) => {
        if (type === 2) {
            content = <img src={Params.HOST + "/file/file.jpg"} alt="" width="100px" onClick={() => this.openFile(url,type)}  />
        } else if (type === 3) {
            content = <Image src={Params.COS_URL + "/" + url} alt="" width="150px" preview={{src: Params.COS_URL + "/" + url}} />
        } else if (type === 4) {
            content = <audio src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" />
        } else if (type === 5) {
            content = <video src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" width='200px' />
        }

        return content;
    }

    /**
     * 查看消息后，去掉未读提醒
     * @param {发送给对应人员的uuid} toUuid
     */
    removeUnreadMessageDot = (toUuid) => {
        let userList = this.props.userList;
        for (var index in userList) {
            if (userList[index].uuid === toUuid) {
                if(userList[index].hasUnreadMessage !== 0){
                    let meUuid = localStorage.getItem('uuid')
                    this.removeUnreadApi(toUuid,meUuid)
                }
                userList[index].hasUnreadMessage = 0;
                this.props.setUserList(userList);
                break;
            }
        }
    }

    /**
     * 移除未读消息
     * @param side 对方uuid （即toUuid）
     * @param me 我的uuid
     */
    removeUnreadApi = (sideUuid,meUuid) => {
        let param = {
            fromUuid:sideUuid,
            toUuid:meUuid
        }
        axiosPut(Params.REMOVE_UNREAD_MESSAGE_URL, param)
            .then(response => {
                if(response.code === -1){
                    message.error(response.msg)
                    return
                }
            })
    }

    /**
     * 获取好友列表
     */
    fetchUserList = () => {
        this.setState({
            menuType: 1,
        })
        let data = {
            uuid: localStorage.uuid
        }
        axiosGet(Params.USER_LIST_URL, data)
            .then(response => {
                let users = response.data
                let data = []
                for (var index in users) {
                    let d = {
                        hasUnreadMessage: this.state.userUnreadNum[users[index].uuid] !== undefined ? this.state.userUnreadNum[users[index].uuid] : 0,
                        username: users[index].email,
                        nickname: users[index].nick_name,
                        companyName: users[index].company_name,
                        uuid: users[index].uuid,
                        messageType: 1,
                        avatar: users[index].avatar !== '' ?  Params.COS_URL + users[index].avatar : Params.HOST + "/file/default.png",
                    }
                    data.push(d)
                }

                this.props.setUserList(data);
                this.chooseUser(data[0])
            })
    }

    showModal = () => {
        this.setState({
            hasUser: true
        });
    };

    addUser = () => {
        let data = {
            uuid: localStorage.uuid,
            friendUsername: this.state.queryUser.username
        }
        axiosPostBody(Params.USER_FRIEND_URL, data)
            .then(_response => {
                message.success("添加成功")
                this.fetchUserList()
                this.setState({
                    hasUser: false
                });
            });
    };

    // joinGroup = () => {
    //     // /group/join/:userUid/:groupUuid
    //     axiosPostBody(Params.GROUP_JOIN_URL + localStorage.uuid + "/" + this.state.queryUser.groupUuid)
    //         .then(_response => {
    //             message.success("添加成功")
    //             // this.fetchUserList()
    //             this.setState({
    //                 hasUser: false
    //             });
    //         });
    // }

    handleCancel = () => {
        this.setState({
            hasUser: false,
            queryUser:{
                username:'',
                nickname:'',
                is_email:false,
            }
        });
    };

    // showCreateGroup = () => {
    //     this.setState({
    //         showCreateGroup: true
    //     });
    // }

    // handleCancelGroup = () => {
    //     this.setState({
    //         showCreateGroup: false
    //     });
    // }

    /**
     * 创建群
     */
    // createGroup = () => {
    //     console.log(this.groupForm.current.getFieldValue())
    //     let values = this.groupForm.current.getFieldValue();
    //     let data = {
    //         name: values.groupName
    //     }
    //
    //     axiosPostBody(Params.GROUP_LIST_URL + "/" + localStorage.uuid, data)
    //         .then(_response => {
    //             message.success("添加成功")
    //             this.setState({
    //                 showCreateGroup: false
    //             });
    //         });
    // }

    render() {
        // const menu = (
        //     <Menu>
        //         <Menu.Item key={1}>
        //             <Button type='link' onClick={this.showModal}>添加用户</Button>
        //         </Menu.Item>
        //         <Menu.Item key={2}>
        //             <Button type='link' onClick={this.showModal}>添加群</Button>
        //         </Menu.Item>
        //         <Menu.Item key={3}>
        //             <Button type='link' onClick={this.showCreateGroup}>创建群</Button>
        //         </Menu.Item>
        //     </Menu>
        // );

        return (
            <>
                <Row>
                    <Col span={24} >
                        <Input.Group compact>
                            <Input.Search allowClear style={{ width: '100%' }} onSearch={this.searchUser} />
                        </Input.Group>
                    </Col>
                    {/*<Col>*/}
                    {/*    <Dropdown overlay={menu} placement="bottomCenter" arrow>*/}
                    {/*        <PlusCircleOutlined style={{ fontSize: 22, color: 'gray', marginLeft: 3, marginTop: 5 }} />*/}
                    {/*    </Dropdown>*/}
                    {/*</Col>*/}
                </Row>


                <Modal title="用户信息" visible={this.state.hasUser} onCancel={this.handleCancel} okText="添加用户" footer={null}>
                    <Input.Group compact>
                        <Input.Search allowClear style={{ width: '100%' }} defaultValue={this.state.queryUser.is_email ? this.state.queryUser.username : this.state.queryUser.nickname} onSearch={this.searchUserApi} />
                    </Input.Group>
                    <br /><hr /><br />

                    <p>用户名：{this.state.queryUser.username}</p>
                    <p>昵称：{this.state.queryUser.nickname}</p>
                    <Button type='primary' onClick={this.addUser} disabled={this.state.queryUser.username == null || this.state.queryUser.username === ''}>添加用户</Button>
                    {/*<br /><br /><hr /><br /><br />*/}

                    {/*<p>群信息：{this.state.queryUser.groupName}</p>*/}
                    {/*<Button type='primary' onClick={this.joinGroup} disabled={this.state.queryUser.groupUuid == null || this.state.queryUser.groupUuid === ''}>添加群</Button>*/}
                </Modal>

                {/*<Modal title="创建群" visible={this.state.showCreateGroup} onCancel={this.handleCancelGroup} onOk={this.createGroup} okText="创建群">*/}
                {/*    <Form*/}
                {/*        name="groupForm"*/}
                {/*        ref={this.groupForm}*/}
                {/*        layout="vertical"*/}
                {/*        autoComplete="off"*/}
                {/*    >*/}
                {/*        <Form.Item*/}
                {/*            name="groupName"*/}
                {/*            label="群名称"*/}
                {/*            rules={[{ required: true }]}*/}
                {/*        >*/}
                {/*            <Input placeholder="群名称" />*/}
                {/*        </Form.Item>*/}
                {/*    </Form>*/}

                {/*</Modal>*/}
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        chooseUser: state.panelReducer.chooseUser,
        user: state.userInfoReducer.user,
        userList: state.panelReducer.userList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => dispatch(actions.setUser(data)),
        setUserList: (data) => dispatch(actions.setUserList(data)),
        setChooseUser: (data) => dispatch(actions.setChooseUser(data)),
        setMessageList: (data) => dispatch(actions.setMessageList(data)),
    }
}

UserSearch = connect(mapStateToProps, mapDispatchToProps)(UserSearch)

export default UserSearch