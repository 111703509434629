import React from 'react';
import {
    List,
    Badge,
    Avatar,
    Image, message,
} from 'antd';
// import {
//     FileWordTwoTone,
// } from '@ant-design/icons';

import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux'
import { actions } from '../../../redux/module/panel'
import * as Params from '../../../common/param/Params'
import {axiosGet, axiosPut} from '../../../util/Request';


class UserList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chooseUser: {},
        }
    }

    componentDidMount() {
    }

    /**
     * 选择用户，获取对应的消息
     * @param {选择的用户} value 
     */
    chooseUser = (value) => {
        let chooseUser = {
            toUser: value.uuid,
            toUsername: value.username,
            toNickname: value.nickname,
            companyName: value.companyName,
            messageType: value.messageType,
            avatar: value.avatar,
        }
        this.fetchMessages(chooseUser);
        this.removeUnreadMessageDot(value.uuid);
    }

    /**
     * 获取消息
     */
    fetchMessages = (chooseUser) => {
        const { messageType, toUser, toUsername } = chooseUser
        let uuid = localStorage.uuid
        if (messageType === 2) {
            uuid = toUser
        }
        let data = {
            Uuid: uuid,
            FriendUsername: toUsername,
            MessageType: messageType
        }

        moment.defineLocale('zh-cn', {
            relativeTime: {
                future: '%s内',
                past: '%s前',
                s: '几秒',
                m: '1分钟',
                mm: '%d分钟',
                h: '1小时',
                hh: '%d小时',
                d: '1天',
                dd: '%d天',
                M: '1个月',
                MM: '%d个月',
                y: '1年',
                yy: '%d年'
            },
        });

        axiosGet(Params.MESSAGE_URL, data)
            .then(response => {
                let comments = []
                let data = response.data
                if (null == data) {
                    data = []
                }
                // console.log('userlist',data)
                for (var i = 0; i < data.length; i++) {
                    let contentType = data[i].contentType
                    let content = this.getContentByType(contentType, data[i].url, data[i].content)

                    let comment = {
                        author: data[i].fromUsername + '('+ data[i].fromCompanyName +')',
                        avatar: <Avatar src={data[i].avatar !== '' ? Params.COS_URL + data[i].avatar : Params.HOST + "/file/default.png"}/>,
                        content: <div style={{whiteSpace:'pre-wrap'}}>{content}</div>,
                        datetime: moment(data[i].createAt).fromNow(),
                    }
                    comments.push(comment)
                }

                this.props.setMessageList(comments);
                // 设置选择的用户信息时，需要先设置消息列表，防止已经完成了滑动到底部动作后，消息才获取完成，导致消息不能完全滑动到底部
                this.props.setChooseUser(chooseUser);
            });
    }

    openFile = (url,type) => {
        if(type === 2 ){
            window.open(Params.COS_URL + '/' + url,'__blank')
        }else{
            return false
        }
    }

    /**
     * 根据文件类型渲染对应的标签，比如视频，图片等。
     * @param {文件类型} type 
     * @param {文件地址} url 
     * @returns 
     */
    getContentByType = (type, url, content) => {
        if (type === 2) {
            content = <img src={Params.HOST + "/file/file.jpg"} alt="" width="100px" onClick={() => this.openFile(url,type)} />
        } else if (type === 3) {
            content = <Image src={Params.COS_URL + "/" + url} alt="" width="150px" preview={{src: Params.COS_URL + "/" + url}}  />
        } else if (type === 4) {
            content = <audio src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" />
        } else if (type === 5) {
            content = <video src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" width='200px' />
        }

        return content;
    }

    /**
     * 查看消息后，去掉未读提醒
     * @param {发送给对应人员的uuid} toUuid 
     */
    removeUnreadMessageDot = (toUuid) => {
        let userList = this.props.userList;
        for (var index in userList) {
            if (userList[index].uuid === toUuid) {
                if(userList[index].hasUnreadMessage !== 0){
                    let meUuid = localStorage.getItem('uuid')
                    this.removeUnreadApi(toUuid,meUuid)
                }
                userList[index].hasUnreadMessage = 0;
                this.props.setUserList(userList);
                break;
            }
        }
    }

    /**
     * 移除未读消息
     * @param side 对方uuid （即toUuid）
     * @param me 我的uuid
     */
    removeUnreadApi = (sideUuid,meUuid) => {
        let param = {
            fromUuid:sideUuid,
            toUuid:meUuid
        }
        axiosPut(Params.REMOVE_UNREAD_MESSAGE_URL, param)
            .then(response => {
                if(response.code === -1){
                    message.error(response.msg)
                    return
                }
            })
    }

    render() {
        return (
            <>
                <div id="userList" style={{
                    height: '695px',
                    overflow: 'auto',
                }}>
                    <InfiniteScroll
                        dataLength={this.props.userList.length}
                        scrollableTarget="userList"
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={this.props.userList}
                            renderItem={item => (

                                    <List.Item>
                                        <List.Item.Meta
                                            style={{ paddingLeft: '10px',display:'flex',alignItems:'center',overflow:"hidden",textOverflow:"ellipsis"}}
                                            onClick={() => this.chooseUser(item)}
                                            avatar={<Badge dot={item.hasUnreadMessage > 0} size="big"><Avatar style={{marginRight:0}} src={item.avatar} /></Badge>}
                                            title={<div style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{item.nickname}</div>}
                                            description={<div style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{item.companyName}</div>}
                                        />
                                    </List.Item>


                            )}
                        />
                    </InfiniteScroll>
                </div>
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        chooseUser: state.panelReducer.chooseUser,
        userList: state.panelReducer.userList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setChooseUser: (data) => dispatch(actions.setChooseUser(data)),
        setUserList: (data) => dispatch(actions.setUserList(data)),
        setMessageList: (data) => dispatch(actions.setMessageList(data)),
    }
}

UserList = connect(mapStateToProps, mapDispatchToProps)(UserList)

export default UserList