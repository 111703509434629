import React from 'react';
import {
    Tooltip,
    Button,
    message,
    Avatar,
    Image,
    Progress
} from 'antd';

import {
    FileAddOutlined,
    // FileOutlined
} from '@ant-design/icons';

import { connect } from 'react-redux'
import { actions } from '../../../redux/module/panel'
import * as Params from "../../../common/param/Params";
import moment from "moment";
import {axiosGet, axiosPut} from "../../../util/Request";


class ChatFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chooseUser:{},
            percent: 0,
            showProgress:false
            // lastFileUrl:''
        }
    }

    componentDidMount() {

    }

    /**
     * 隐藏真正的文件上传控件，通过按钮模拟点击文件上传控件
     */
    clickFile = () => {
        let file = document.getElementById("file")
        file.click();
    }

    /**
     * 上传文件
     * @param {事件} e
     * @returns
     */
    uploadFile = (e) => {
        this.setState({
            showProgress:true
        })
        let files = e.target.files
        if (!files || !files[0]) {
            return;
        }
        let fileName = files[0].name
        if (null == fileName) {
            message.error("文件无名称")
            return
        }
        let index = fileName.lastIndexOf('.');
        let fileSuffix = null;
        if (index >= 0) {
            fileSuffix = fileName.substring(index + 1);
        }

        if(["jpeg", "jpg", "png", "gif", "pdf", "xls", "doc", "word", "xlsx", "cvs", "docx", "txt"].indexOf(fileSuffix) === -1){
            message.error("不支持该文件消息")
            return
        }
        // let currentTime = Date.now()
        // console.log(currentTime)
        let reader = new FileReader()



        reader.onload = ((event) => {
            this.onUploadProgress(event)
            let file = event.target.result
            // Uint8数组可以直观的看到ArrayBuffer中每个字节（1字节 == 8位）的值。一般我们要将ArrayBuffer转成Uint类型数组后才能对其中的字节进行存取操作。
            // 上传文件必须转换为Uint8Array
            var u8 = new Uint8Array(file);

            let data = {
                content: this.state.value,
                contentType: 3,
                fileSuffix: fileSuffix,
                file: u8
            }

            this.props.sendMessage(data)

            // let param = {
            //     ContentType: ["jpeg", "jpg", "png", "gif"].indexOf(fileSuffix) !== -1 ? 3 : 2,
            //     MessageType:1,
            //     Uuid:localStorage.getItem('uuid'),
            //     FriendUsername: this.props.chooseUser.toUsername,
            //     // AfterTime:currentTime,
            // }
            // // this.getSendFile(param); //获取发送的文件名称
            //
            if (["jpeg", "jpg", "png", "gif"].indexOf(fileSuffix) !== -1) {
                this.props.appendImgToPanel(file)
            }else{
                this.props.appendMessage(<div style={{width:100,height:105,position:"relative"}} ><Progress type="circle" style={{position:"absolute",left:0,top:0,zIndex:2,display:this.state.showProgress ? 'block' : 'none',backgroundColor:"rgba(0,0,0,0.45)"}} percent={this.state.percent} width={100} size="small" /><img style={{position:"absolute",left:0,top:0,zIndex:1}} src={Params.HOST + "/file/file.jpg"} alt="" width="100px" /></div>)
            }
            // else {
                // this.props.appendMessage(<FileOutlined style={{ fontSize: 38 }} />)
                // this.props.appendMessage(<img src={Params.HOST + "/file/file.jpg"} alt="" width="100px" />)
            // }
        })
        reader.readAsArrayBuffer(files[0])
        setTimeout(()=>{
            this.setState({
                showProgress:false,
                percent:0,
            })
            this.fetchMessages(this.props.chooseUser)
        },2000)
    }

    // /**
    //  * 获取最后发送消息的文件名称
    //  */
    // getSendFile = (param) => {
    //     axiosGet(Params.LAST_MESSAGE_URL, param)
    //         .then(response => {
    //             let data = response.data
    //
    //             if (null == data) {
    //                 this.setState({
    //                     lastFileUrl:''
    //                 })
    //             }
    //
    //             this.setState({
    //                 lastFileUrl:data.url
    //             })
    //         })
    // }


    /**
     * 获取消息
     */
    fetchMessages = (chooseUser) => {
        // console.log('this?')
        const { messageType, toUser, toUsername } = chooseUser
        let uuid = localStorage.uuid
        if (messageType === 2) {
            uuid = toUser
        }
        let data = {
            Uuid: uuid,
            FriendUsername: toUsername,
            MessageType: messageType
        }

        moment.defineLocale('zh-cn', {
            relativeTime: {
                future: '%s内',
                past: '%s前',
                s: '几秒',
                m: '1分钟',
                mm: '%d分钟',
                h: '1小时',
                hh: '%d小时',
                d: '1天',
                dd: '%d天',
                M: '1个月',
                MM: '%d个月',
                y: '1年',
                yy: '%d年'
            },
        });

        axiosGet(Params.MESSAGE_URL, data)
            .then(response => {
                let comments = []
                let data = response.data
                // console.log(data)
                if (null == data) {
                    data = []
                }
                // console.log('chatfile',data)
                for (var i = 0; i < data.length; i++) {
                    let contentType = data[i].contentType
                    // console.log(data[i])
                    let content = this.getContentByType(contentType, data[i].url, data[i].content)

                    let comment = {
                        author: data[i].fromUsername + '('+ data[i].fromCompanyName +')',
                        avatar: <Avatar src={data[i].avatar !== '' ? Params.COS_URL + data[i].avatar : Params.HOST + "/file/default.png"}/>,
                        content: <div style={{whiteSpace:'pre-wrap'}}>{content}</div>,
                        datetime: moment(data[i].createAt).fromNow(),
                    }
                    comments.push(comment)
                }

                this.props.setMessageList(comments);
                // 设置选择的用户信息时，需要先设置消息列表，防止已经完成了滑动到底部动作后，消息才获取完成，导致消息不能完全滑动到底部
                this.props.setChooseUser(chooseUser);
                this.scrollToBottom()

            });
    }

    /**
     * 发送消息或者接受消息后，滚动到最后
     */
    scrollToBottom = () => {
        let div = document.getElementById("scrollableDiv")
        // console.log('div.clientHeight:'+div.clientHeight)
        // console.log('div.scrollHeight:'+div.scrollHeight)
        div.scrollTop = div.scrollHeight
    }


    openFile = (url,type) => {
        if(type === 2 ){
            window.open(Params.COS_URL + '/' + url,'__blank')
        }else{
            return false
        }
    }

    /**
     * 根据文件类型渲染对应的标签，比如视频，图片等。
     * @param {文件类型} type
     * @param {文件地址} url
     * @returns
     */
    getContentByType = (type, url, content) => {
        if (type === 2) {
            content = <img src={Params.HOST + "/file/file.jpg"} alt="" width="100px" onClick={() => this.openFile(url,type)} />
        } else if (type === 3) {
            content = <Image src={Params.COS_URL + "/" + url} alt="" width="150px" preview={{src: Params.COS_URL + "/" + url}}  />
        } else if (type === 4) {
            content = <audio src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" />
        } else if (type === 5) {
            content = <video src={Params.COS_URL + "/" + url} controls autoPlay={false} preload="auto" width='200px' />
        }

        return content;
    }

    /**
     * 查看消息后，去掉未读提醒
     * @param {发送给对应人员的uuid} toUuid
     */
    removeUnreadMessageDot = (toUuid) => {
        let userList = this.props.userList;
        for (var index in userList) {
            if (userList[index].uuid === toUuid) {
                if(userList[index].hasUnreadMessage !== 0){
                    let meUuid = localStorage.getItem('uuid')
                    this.removeUnreadApi(toUuid,meUuid)
                }
                userList[index].hasUnreadMessage = 0;
                this.props.setUserList(userList);
                break;
            }
        }
    }

    /**
     * 移除未读消息
     * @param side 对方uuid （即toUuid）
     * @param me 我的uuid
     */
    removeUnreadApi = (sideUuid,meUuid) => {
        let param = {
            fromUuid:sideUuid,
            toUuid:meUuid
        }
        axiosPut(Params.REMOVE_UNREAD_MESSAGE_URL, param)
            .then(response => {
                if(response.code === -1){
                    message.error(response.msg)
                    return
                }
            })
    }

    onUploadProgress = (progressEvent) =>{
        //进度
        let percent = (Math.round(progressEvent.loaded / progressEvent.total * 100))
        // console.log(percent);
        this.setState({percent:percent})
    }



    render() {
        const { chooseUser } = this.props;
        return (
            <>
                <Tooltip title="上传图片或者文件">
                    <input type='file' id='file' onChange={this.uploadFile} hidden disabled={chooseUser.toUser === ''} />
                    <Button
                        onClick={this.clickFile}
                        shape="circle"
                        style={{ marginRight: 10 }}
                        icon={<FileAddOutlined />}
                        disabled={chooseUser.toUser === ''}
                    />
                </Tooltip>
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userInfoReducer.user,
        chooseUser: state.panelReducer.chooseUser,
        messageList: state.panelReducer.messageList,
        socket: state.panelReducer.socket,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setMessageList: (data) => dispatch(actions.setMessageList(data)),
        setChooseUser: (data) => dispatch(actions.setChooseUser(data)),
    }
}

ChatFile = connect(mapStateToProps, mapDispatchToProps)(ChatFile)

export default ChatFile